
import AvatarComponent from "@/components/elements/avatar/avatar.component.vue";
import { Component, Vue } from "vue-property-decorator";
import { TimelineMax, TweenMax } from "gsap";
@Component({
  name: "re-graphic-component",
  components: { AvatarComponent },
})
export default class ReGraphicComponent extends Vue {
  avatar = parseInt(localStorage.getItem("avatar")) || 6;
  points = [{ left: "29rem", top: "10rem" }];
  mounted() {
    window.addEventListener("avatar-localstorage-changed", (event) => {
      this.avatar = event.detail.storage;
    });
    var tween = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-re", 0.5, {
          css: {
            display: "block",
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-re", 1, {
          css: {
            left: `calc(${
              document.getElementById("re-point-0").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("re-point-0").style.top
            } - 40px)`,
          },
        })
      );
    const scene1 = this.$scrollmagic
      .scene({
        triggerElement: "#re-point-0",
        triggerHook: 0.5,
        duration: 50,
      })
      // Declaration of animation and attaching to element

      .setTween(tween);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "2 (duration: 300)" });

    this.$scrollmagic.addScene(scene1);
  }
}
