import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);
// Create a new store instance.
export const store = new Vuex.Store({
  state: {
    count: 0,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
  },
});
