import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeComponent from "../components/pages/_home/home.component.vue";
import RemoteWorkPageComponent from "../components/pages/remote_work_page/remote_work.component.vue";
import EducationPageComponent from "../components/pages/education/education.component.vue";
import RemoteEventsPageComponent from "../components/pages/remote_events/remote_events_page.component.vue";
import LegalTermsComponent from "../components/pages/legalTerms/legalTerms.component.vue";
import SchoolFormComponent from "../components/pages/school_form/schoolForm.component.vue";
import AboutUsComponent from "../components/pages/about_us/aboutUs.component.vue";
import GetStartedComponent from "../components/pages/get_started/getStarted.component.vue";
import TeamWorkComponent from "../components/pages/team_work/teamWork.component.vue";
import VisitNubiComponent from "../components/pages/visit_nubi/visit_nubi.component.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeComponent,
  },
  {
    path: "/remote_work",
    name: "remote_work",
    component: RemoteWorkPageComponent,
  },
  {
    path: "/school",
    name: "school",
    component: EducationPageComponent,
    beforeEnter: () => {
      window.location.href = "https://hawkings.education/";
    },
  },
  {
    path: "/event",
    name: "event",
    component: RemoteEventsPageComponent,
  },
  {
    path: "/legal_terms",
    name: "legal_terms",
    component: LegalTermsComponent,
  },
  {
    path: "/school_form",
    name: "school_form",
    component: SchoolFormComponent,
    beforeEnter: () => {
      window.location.href = "https://hawkings.education/";
    },
  },
  {
    path: "/about_us",
    name: "about_us",
    component: AboutUsComponent,
  },
  {
    path: "/get-started",
    name: "get-started",
    component: GetStartedComponent,
  },
  {
    path: "/team-work",
    name: "team-work",
    component: TeamWorkComponent,
  },
  {
    path: "/visit-nubi",
    name: "visit-nubi",
    component: VisitNubiComponent,
  },
  {
    path: "/bot/i",
    name: "home",
    component: HomeComponent,
    beforeEnter: () => {
      window.location.href =
        "https://chats.landbot.io/v3/H-792508-K27TBITQUGO6XMDA/index.html";
    },
  },
  {
    path: "/bot/c",
    name: "home",
    component: HomeComponent,
    beforeEnter: () => {
      window.location.href =
        "https://chats.landbot.io/v3/H-792508-K27TBITQUGO6XMDA/index.html";
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
