
import { Vue, Component } from "vue-property-decorator";
@Component({
  name: "footer-component",
})
export default class FooterComponent extends Vue {
  goTo(link) {
    this.$router.push({ name: link });
  }
}
