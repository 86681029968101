var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"remote-work"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("What does nubi offer?")]),_c('div',{staticClass:"flex row rw-third"},[_c('div',{staticClass:"col-12 col-lg-5 card"},[_c('h3',[_vm._v("Meeting rooms")]),_c('div',{staticClass:"room"},[_c('avatar-component',{attrs:{"htmlId":"room__avatar_1","id":"3","name":"Elena","move":"true"}}),_c('avatar-component',{attrs:{"htmlId":"room__avatar_2","id":"5","name":"Adrian","move":"true"}}),_c('avatar-component',{attrs:{"id":"7","htmlId":"room__avatar_3","name":"Gustavo","move":"true"}}),_c('img',{attrs:{"src":require("@/assets/svg/remote_work/remote_work_room.svg"),"alt":""}})],1),_c('p',[_vm._v(" join virtual meeting rooms with your team, other teammates will see you and be able to join ")])]),_c('div',{staticClass:"col-12 col-lg-5 card"},[_c('h3',[_vm._v("Can you talk?")]),_c('div',{staticClass:"avatar__state"},[_vm._m(0),_c('avatar-component'),_vm._m(1)],1),_c('p',[_vm._v(" you will know what your teammates are up to and they’ll know about you ")])]),_vm._m(2),_c('div',{staticClass:"col-12 col-lg-5 card"},[_c('h3',[_vm._v("nubi chat")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","height":"16rem"}},[_c('div',{staticClass:"flex"},[_vm._m(3),_vm._m(4),_c('div',[_c('avatar-component',{attrs:{"id":"5"}}),_c('avatar-component',{attrs:{"id":"6"}})],1)])]),_c('p',[_vm._v(" use nubi’s own chat system or it can be integrated with Slack, MS Teams or Discord. ")])]),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"states"},[_c('ul',[_c('li',{attrs:{"id":"available"}},[_vm._v("Available")]),_c('li',{staticClass:"active",attrs:{"id":"busy"}},[_vm._v("Busy")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reasons"},[_c('ul',[_c('li',[_vm._v("🥗 Eating")]),_c('li',[_vm._v("😴 Resting")]),_c('li',[_vm._v("💪 Focused")]),_c('li',[_vm._v("📞 In a Call")]),_c('li',[_vm._v("⛱ On Vacation")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-lg-5 card"},[_c('h3',[_vm._v("Power ups")]),_c('div',{staticClass:"flex toolkits"},[_c('div',{staticClass:"rw-row"},[_c('div',{staticClass:"culture-card"},[_c('img',{attrs:{"src":require("@/assets/icons/polls.svg"),"alt":""}}),_c('span',[_vm._v("nubi POLLS")])]),_c('div',{staticClass:"culture-card"},[_c('img',{attrs:{"src":require("@/assets/icons/weekly_report.svg"),"alt":""}}),_c('span',[_vm._v("weekly report ")])]),_c('div',{staticClass:"culture-card"},[_c('img',{attrs:{"src":require("@/assets/icons/oneChance.svg"),"alt":""}}),_c('span',[_vm._v("ONE-TIME PASSWORD")])])]),_c('div',{staticClass:"rw-row"},[_c('div',{staticClass:"culture-card"},[_c('img',{attrs:{"src":require("@/assets/icons/team_photos.svg"),"alt":""}}),_c('span',[_vm._v("COMPANY PHOTOS")])]),_c('div',{staticClass:"culture-card"},[_c('img',{attrs:{"src":require("@/assets/icons/polls.svg"),"alt":""}}),_c('span',[_vm._v("nubi POLLS")])]),_c('div',{staticClass:"culture-card"},[_c('img',{attrs:{"src":require("@/assets/img/logo/nubi_blue.png"),"alt":""}}),_c('span',[_vm._v("nubi files")])])])]),_c('p',[_vm._v(" daily/weekly reports for your team, reserve your physical spot in the office, share your photos with the team and more… ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"bubble-chat"},[_c('i',{staticClass:"fa-solid fa-ellipsis"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bubble-float"},[_c('div',{staticClass:"bubble-chat white emoji",attrs:{"id":"emoji"}},[_vm._v("👌")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-lg-5 card"},[_c('h3',[_vm._v("Invite")]),_c('div',{staticClass:"flex toolkits",staticStyle:{"justify-content":"center"}},[_c('img',{staticStyle:{"height":"80%"},attrs:{"src":require("@/assets/icons/invitation.png"),"alt":""}})]),_c('p',[_vm._v(" external people to your virtual office and let them join corporate events or specific meeting ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-lg-5 card"},[_c('h3',[_vm._v("Mobile app")]),_c('div',{staticClass:"flex toolkits",staticStyle:{"justify-content":"center"}},[_c('img',{staticStyle:{"height":"80%"},attrs:{"src":require("@/assets/icons/mobile-phone.png"),"alt":""}})]),_c('p',[_vm._v("that allows you to be in contact with your team in nubi")])])
}]

export { render, staticRenderFns }