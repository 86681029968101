
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import MainService from "../../../services/main.service";
import Form from "@/resources/mixins/form/form";
@Component({
  name: "get-started-component",
  components: {
    HeaderComponent,
    FooterComponent,
  },
})
export default class GetStartedComponent extends mixins(Form) {
  spaceSelected = null;
  spaceName = "";
  email = "";
  nubiType = null;
  _mainService = null;
  spacesTypes = [];
  mounted() {
    this._mainService = new MainService();
  }
  isDisabled() {
    if (!this.spaceName || !this.email || this.spaceSelected === null) {
      return true;
    }
    if (!this.validateEmail(this.email)) {
      return true;
    }
    return false;
  }
  selectType(type) {
    this.nubiType = type;
    if (type === null) {
      this.spaceSelected = null;
      this.spaceName = "";
      this.email = "";
    }
    if (type !== null) {
      this.spacesTypes = [];
      this._mainService
        .getSpacesTypes(type)
        .then((response) => {
          this.spacesTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  selectSpace(id) {
    this.spaceSelected = id;
  }
  sendRequest() {
    const params = {
      name: this.spaceName,
      type: this.nubiType,
      createdByUserEmail: this.email,
      scenarioType: this.spaceSelected,
    };

    this.sendEmailSpace(params);

    this._mainService.postSpaceRequest(params).then((res) => {
      const requestConfirmation = document.getElementById(
        "request-confirmation"
      );
      requestConfirmation.style.display = "block";
    });

    this.spaceSelected = null;
    this.spaceName = "";
    this.email = "";
  }
}
