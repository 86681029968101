
import { Component, Vue } from "vue-property-decorator";
import ReGraphicComponent from "./re_graphic.component.vue";
@Component({
  name: "remote-events-component",
  components: { ReGraphicComponent },
})
export default class RemoteEventsComponent extends Vue {
  goTo(link) {
    this.$router.push({ name: link });
  }
}
