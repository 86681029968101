
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import { mixins } from "vue-class-component";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import { Component, Vue } from "vue-property-decorator";
import EGraphicComponent from "../_home/components/education/e_graphic.component.vue";
import Form from "@/resources/mixins/form/form";
@Component({
  name: "school-form-component",
  components: {
    HeaderComponent,
    FooterComponent,
    EGraphicComponent,
  },
})
export default class SchoolFormComponent extends mixins(Form) {
  name = "";
  email = "";
  message = "";
  buttonSend() {
    this.sendEmail({
      name: this.name,
      email: this.email,
      message: this.message,
    });
  }
  isDisabled() {
    if (!this.name || !this.email || !this.message) {
      return true;
    }
    if (!this.validateEmail(this.email)) {
      return true;
    }
    return false;
  }
}
