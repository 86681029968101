import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Form extends Vue {
  sendEmail(params) {
    const { name, email, message } = params;

    const jsonData = JSON.stringify({
      name,
      email,
      message,
    });

    const formData = new FormData();
    formData.append("json", encodeURIComponent(jsonData));
    fetch("/php/send-mail-school.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        const emailConfirmation = document.getElementById("email-confirmation");
        emailConfirmation.style.display = "block";
      })
      .catch((res) => {
        return;
      });
  }

  sendEmailSpace(params) {
    const { name, createdByUserEmail, type, scenarioType } = params;

    const jsonData = JSON.stringify({
      name,
      type,
      scenarioType,
      createdByUserEmail,
    });

    const formData = new FormData();
    formData.append("json", encodeURIComponent(jsonData));
    fetch("/php/send-mail.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return;
      })
      .catch((res) => {
        return;
      });
  }

  validateEmail(mail: string) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(mail)) {
      return true;
    }
    return false;
  }
}
