
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import { Component, Vue } from "vue-property-decorator";
import EFirstComponent from "./components/e_first.component.vue";
import ESecondComponent from "./components/e_second.component.vue";
import EThirdComponent from "./components/e_third.component.vue";
@Component({
  name: "education-page-component",
  components: {
    HeaderComponent,
    FooterComponent,
    EFirstComponent,
    ESecondComponent,
    EThirdComponent,
  },
})
export default class EducationPageComponent extends Vue {}
