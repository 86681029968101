
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "cookie-message-component",
})
export default class CookieMessageComponent extends Vue {
  mounted() {
    const firstVisit = this.getCookie("fv");
    console.log("firstVisit", firstVisit);

    if (firstVisit) {
      document.getElementById("cookie-container").style.display = "none";
      this.setCookie("fv", 1, 30);
    }
  }
  cookieButton(event) {
    document.getElementById("cookie-container").style.display = "none";
    this.setCookie("fv", 1, 30);
  }
  setCookie(c_name, value, exdays) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    let c_value =
      escape(value) +
      (exdays === null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
  }

  getCookie(c_name) {
    let c_value = document.cookie;
    let c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start === -1) {
      c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start === -1) {
      c_value = null;
    } else {
      c_start = c_value.indexOf("=", c_start) + 1;
      let c_end = c_value.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = c_value.length;
      }
      c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
  }
}
