
import AvatarComponent from "@/components/elements/avatar/avatar.component.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "avatar-selector-component",
  components: { AvatarComponent },
})
export default class AvatarSelectorComponent extends Vue {
  activeAvatar = 6;
  items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  direction = "left";
  mounted() {
    localStorage.setItem("avatar", this.activeAvatar.toString());

    window.dispatchEvent(
      new CustomEvent("avatar-localstorage-changed", {
        detail: {
          storage: localStorage.getItem("avatar"),
        },
      })
    );
  }
  change(index) {
    this.activeAvatar += index;
    if (this.activeAvatar < 1) this.activeAvatar = 11;
    if (this.activeAvatar > 11) this.activeAvatar = 1;
  }
  moveLeft() {
    var newActive = this.activeAvatar;
    newActive--;
    this.activeAvatar = newActive < 1 ? this.items.length : newActive;
    localStorage.setItem("avatar", this.activeAvatar.toString());

    window.dispatchEvent(
      new CustomEvent("avatar-localstorage-changed", {
        detail: {
          storage: localStorage.getItem("avatar"),
        },
      })
    );

    this.direction = "left";
    this.moveSelector();
  }

  moveRight() {
    var newActive = this.activeAvatar;
    newActive++;
    this.activeAvatar = newActive > this.items.length ? 1 : newActive;
    localStorage.setItem("avatar", this.activeAvatar.toString());

    window.dispatchEvent(
      new CustomEvent("avatar-localstorage-changed", {
        detail: {
          storage: localStorage.getItem("avatar"),
        },
      })
    );

    this.direction = "right";
    this.moveSelector();
  }

  moveSelector() {
    const elements = document.querySelectorAll(".avatar-container ");
    for (const element of elements) {
      const level = element?.className.split(" ")[2];
      const index = parseInt(level?.split("-")[2]);
      const newIndex =
        this.direction === "left"
          ? index == this.items.length
            ? 1
            : index + 1
          : index == 1
          ? 11
          : index - 1;
      element.className = element.className.replace(
        level,
        `avatar-level-${newIndex}`
      );
    }
  }
}
