
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import { Component, Vue } from "vue-property-decorator";
import AvatarComponent from "../../elements/avatar/avatar.component.vue";
@Component({
  name: "about-us-component",
  components: {
    HeaderComponent,
    FooterComponent,
    AvatarComponent,
  },
})
export default class AboutUsComponent extends Vue {}
