
import { Component, Vue } from "vue-property-decorator";
import RwGraphicComponent from "./rw_graphic.component.vue";
@Component({
  name: "remote-work-component",
  components: { RwGraphicComponent },
})
export default class RemoteWorkComponent extends Vue {
  goTo(link) {
    this.$router.push({ name: link });
  }
  nextLevel() {
    const id = "school";
    const yOffset = 125;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }
}
