
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import { Component, Vue } from "vue-property-decorator";
import ReFirstComponent from "./components/re_first.component.vue";
import ReSecondComponent from "./components/re_second.component.vue";
import ReThirdComponent from "./components/re_third.component.vue";
@Component({
  name: "remote-events-page-component",
  components: {
    HeaderComponent,
    FooterComponent,
    ReFirstComponent,
    ReSecondComponent,
    ReThirdComponent,
  },
})
export default class RemoteEventsPageComponent extends Vue {}
