
import { Vue, Component, Prop } from "vue-property-decorator";
import nubiUrls from "../../../../public/nubi_urls";
@Component({
  name: "header-component",
})
export default class HeaderComponent extends Vue {
  @Prop({ default: "" }) type: string;
  nubiUrls = nubiUrls;
  menuClosed = true;
  mounted() {
    const header = document.querySelector("header");

    if (this.type === "remote-work") {
      header.className = "intro-header";
    } else if (this.type === "nubi-school") {
      header.className = "school-header";
    } else if (this.type === "events") {
      header.className = "events-header";
    } else if (this.type === "visit-nubi") {
      header.className = "hero-header";
    }

    if (this.type === "") {
      document.addEventListener("scroll", () => {
        const header = document.querySelector("header");

        if (this.isInViewport(document.getElementById("hero"))) {
          header.className = "hero-header";
        }
        if (this.isInViewport(document.getElementById("intro"))) {
          header.className = "intro-header";
        }
        if (this.isInViewport(document.getElementById("school"))) {
          header.className = "school-header";
        }
        if (this.isInViewport(document.getElementById("events"))) {
          header.className = "events-header";
        }
        if (this.isInViewport(document.getElementById("success"))) {
          header.className = "success-header";
        }
        if (this.isInViewport(document.getElementById("join_nubi"))) {
          header.className = "join-nubi-header";
        }
      });
    }
  }
  active(link) {
    return this.$route.name === link ? "active" : "";
  }
  goTo(link) {
    this.$router.push({ name: link });
  }
  getNubiUrl() {
    return this.$route.name === "school" ? "school_form" : "visit-nubi";
  }
  isInViewport(element) {
    const rect = element?.getBoundingClientRect();

    return rect?.top <= 75;
  }
}
