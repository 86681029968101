
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "avatar-component",
})
export default class AvatarComponent extends Vue {
  @Prop({ default: "" }) htmlId;
  @Prop({ default: 1 }) id: number;
  @Prop({ default: false }) move;
  @Prop({ default: "" }) name: string;
}
