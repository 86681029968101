
import { Component, Prop, Vue } from "vue-property-decorator";
import AvatarComponent from "../../../../elements/avatar/avatar.component.vue";
import { TimelineMax, TweenMax, Linear } from "gsap";
@Component({
  name: "rw-graphic-component",
  components: { AvatarComponent },
})
export default class RwGraphicComponent extends Vue {
  @Prop({ default: "" }) id: string;
  avatar = parseInt(localStorage.getItem("avatar")) || 6;
  points = [
    { left: "39rem", top: "24px" },
    { left: "40rem", top: "40px" },
    { left: "43rem", top: "121px" },
    { left: "38rem", top: "170px" },
    { left: "36rem", top: "253px" },
    { left: "33rem", top: "272px" },
    { left: "32rem", top: "326px" },
    { left: "32rem", top: "350px" },
    { left: "34rem", top: "25rem" },
    { left: "36rem", top: "26rem" },
    { left: "38rem", top: "29rem" },
    { left: "38rem", top: "30.5rem" },
    { left: "36rem", top: "32.5rem" },
    { left: "32rem", top: "38.5rem" },
    { left: "27rem", top: "48rem" },
    { left: "31.5rem", top: "48rem" },
  ];
  mounted() {
    window.addEventListener("avatar-localstorage-changed", (event) => {
      this.avatar = event.detail.storage;
    });
    var tween = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move", 0.5, {
          css: {
            display: "none",
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 0.5, {
          css: {
            display: "block",
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-0").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-0").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-1").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-1").style.top
            } - 40px)`,
          },
        })
      );
    const scene1 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-0",
        triggerHook: 0.5,
        duration: 50,
      })
      // Declaration of animation and attaching to element

      .setTween(tween);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "2 (duration: 300)" });
    var tween2 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-2").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-2").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 2, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-3").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-3").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );

    const scene2 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-2",
        triggerHook: 0.5,
        duration: 100,
      })
      // Declaration of animation and attaching to element

      .setTween(tween2);
    // Helpful tags for orientation on the screen
    // .addIndicators({ name: "2 (duration: 300)" });

    var tween3 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-4").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-4").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 2, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-5").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-5").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );
    const scene3 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-4",
        triggerHook: 0.5,
        duration: 50,
      })
      // Declaration of animation and attaching to element

      .setTween(tween3);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "3 (duration: 300)" });

    var tween4 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-6").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-6").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 2, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-7").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-7").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );
    const scene4 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-6",
        triggerHook: 0.5,
        duration: 30,
      })
      // Declaration of animation and attaching to element

      .setTween(tween4);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "3 (duration: 300)" });

    var tween5 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-8").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-8").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 2, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-9").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-9").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );
    const scene5 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-8",
        triggerHook: 0.5,
        duration: 30,
      })
      // Declaration of animation and attaching to element

      .setTween(tween5);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "3 (duration: 300)" });

    var tween6 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-10").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-10").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 2, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-11").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-11").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );
    const scene6 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-10",
        triggerHook: 0.5,
        duration: 30,
      })
      // Declaration of animation and attaching to element

      .setTween(tween6);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "3 (duration: 300)" });

    var tween7 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-12").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-12").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 2, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-13").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-13").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );
    const scene7 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-12",
        triggerHook: 0.5,
        duration: 100,
      })
      // Declaration of animation and attaching to element

      .setTween(tween7);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "3 (duration: 300)" });

    var tween8 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-14").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-14").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-rw", 1, {
          css: {
            left: `calc(${
              document.getElementById("rw-point-15").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("rw-point-15").style.top
            } - 40px)`,
          },
          ease: Linear.easeIn,
        })
      );
    const scene8 = this.$scrollmagic
      .scene({
        triggerElement: "#rw-point-14",
        triggerHook: 0.5,
        duration: 100,
      })
      // Declaration of animation and attaching to element

      .setTween(tween8);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "3 (duration: 300)" });

    this.$scrollmagic.addScene(scene1);
    this.$scrollmagic.addScene(scene2);
    this.$scrollmagic.addScene(scene3);
    this.$scrollmagic.addScene(scene4);
    this.$scrollmagic.addScene(scene5);
    this.$scrollmagic.addScene(scene6);
    this.$scrollmagic.addScene(scene7);
    this.$scrollmagic.addScene(scene8);
  }
  isInViewport(element) {
    const rect = element.getBoundingClientRect();

    return rect.top <= 75;
  }
}
