
import { Component, Vue } from "vue-property-decorator";
import AvatarSelectorComponent from "./avatarSelector.component.vue";
@Component({
  name: "hero-component",
  components: { AvatarSelectorComponent },
})
export default class HeroComponent extends Vue {
  start() {
    const id = "remote_work";
    const yOffset = -25;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }
}
