import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollmagic from "vue-scrollmagic";
import "aos/dist/aos.css";
import AOS from "aos";
import Vuex from "vuex";
import { store } from "./store/index";

Vue.use(VueScrollmagic);
declare module "vue/types/vue" {
  interface Vue {
    $scrollmagic: VueScrollmagic;
  }
}
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init();
    AOS.refreshHard();
  },
}).$mount("#app");
