
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import SpaceComponent from "@/components/pages/visit_nubi/components/space.component.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "visit-nubi-component",
  components: {
    HeaderComponent,
    FooterComponent,
    SpaceComponent,
  },
})
export default class VisitNubiComponent extends Vue {
  spaces = [
    {
      id: 1,
      image:
        "https://assets-pro.nubi.city/scenario-type/11/627e07f6d05ab1d6eebd95e14399975dee575.png",
      name: "Events I",
      description: "Differents events, in nature, up to 150 avatars",
      type: "EVENT",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 2,
      image:
        "https://assets-pro.nubi.city/scenario-type/10/627e07f69ed08b6626f12f3a9f761cc63564f.jpg",
      name: "Events II",
      description: `For events with more than 250 avatars. Double conference sessions. 24 booths for exhibitors or sponsors. 
        Meeting rooms and networking`,
      type: "EVENT",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 3,
      image:
        "https://assets-pro.nubi.city/scenario-type/14/62b1722c9bea971cbf60ee6836af1f5761418.png",
      name: "Events III",
      description: `Webinars and meetings area. Perfect for up to 150 avatars
        in single events`,
      type: "EVENT",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 4,
      image:
        "https://assets-pro.nubi.city/scenario-type/17/6315a1bdea869c696427c9bed9af0a7a8a8a2.png",
      name: "Events IIII",
      description: `Events with up to 250 avatars. Modern and
        practical design. Sponsors, networking and webinar areas`,
      type: "EVENT",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 5,
      image:
        "https://assets-pro.nubi.city/scenario-type/4/627e07f55d6ebae29eb5ea62ebf5c56d7025f.jpg",
      name: "Learning",
      description: `Although all the spaces can be used as classrooms,
        this has been specially prepared for it`,
      type: "SCHOOL",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 6,
      image:
        "https://assets-pro.nubi.city/scenario-type/8/627e07f63e306190f3796843498959552b677.jpg",
      name: "Meditation",
      description: `Add this space to your corporate campus or contact with us
        if you wanto assist to some of the public meditation sessions`,
      type: "EVENT",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 7,
      image:
        "https://assets-pro.nubi.city/scenario-type/5/627e07f58e3ea4d96d8393bafc395bf773b20.jpg",
      name: "Office I",
      description: "9 meeting rooms, webinar area and up to 50 avatars",
      type: "OFFICE",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 8,
      image:
        "https://assets-pro.nubi.city/scenario-type/1/627e07f42f386b0d6af81b2c5ebe6dd186da8.jpg",
      name: "Office II",
      description: `9 meeting rooms. Classic design. Webinar and networking.
        Caribe room to know who is in vacation and kitchen for when you are resting`,
      type: "OFFICE",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 9,
      image:
        "https://assets-pro.nubi.city/scenario-type/18/6315a1c3c1d2474c802d63021be37bfaff629.png",
      name: "Office III",
      description: `Modern design, 11 meeting rooms. Upper floor with webinar and
        networking`,
      type: "OFFICE",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 10,
      image:
        "https://assets-pro.nubi.city/scenario-type/2/627e07f4d952fb50669cee8199fc127612afe.jpg",
      name: "Welcome",
      description: `Space with webinar area and 3 meeting rooms.
        Ideal for work up to 20 avatars`,
      type: "OFFICE",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
    {
      id: 11,
      image:
        "https://assets-pro.nubi.city/scenario-type/9/62a04cd81122f8a6103a94109aa098326a609.jpg",
      name: "Ecommerce",
      description: `Do you want to know what a nubi shopping experience is like?
        This is your place to check`,
      type: "OFFICE",
      url: "https://app.nubi.city/invitation/guest/1204639343187e0449f97f808312ebf08513abc73/Welcome_space?spaceId=1481&hasOnboarding=true",
    },
  ];
}
