
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "team-work-component",
  components: {
    HeaderComponent,
    FooterComponent,
  },
})
export default class TeamWorkComponent extends Vue {}
