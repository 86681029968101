
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  name: "space-component",
  components: {},
})
export default class SpaceComponent extends Vue {
  @Prop({ default: "" }) image: string;
  @Prop({ default: "" }) name: string;
  @Prop({ default: "" }) description: string;
  @Prop({ default: "" }) type: string;
  @Prop({ default: "" }) url: string;

  getTypeStyle() {
    switch (this.type) {
      case "OFFICE":
        return "office-badge";
      case "EVENT":
        return "event-badge";
      case "SCHOOL":
        return "school-badge";
      default:
        return "";
    }
  }
  getTypeName() {
    switch (this.type) {
      case "OFFICE":
        return "Office";
      case "EVENT":
        return "Event";
      case "SCHOOL":
        return "School";
      default:
        return "";
    }
  }
}
