
import { Vue, Component } from "vue-property-decorator";
import HeaderComponent from "../../elements/header/header.component.vue";
import FooterComponent from "../../elements/footer/footer.component.vue";
import RwFirstComponent from "./components/rw_first.component.vue";
import RwSecondComponent from "./components/rw_second.component.vue";
import RwThirdComponent from "./components/rw_third.component.vue";
import RwFourComponent from "./components/rw_four.component.vue";
@Component({
  name: "remote-work-page-component",
  components: {
    HeaderComponent,
    FooterComponent,
    RwFirstComponent,
    RwSecondComponent,
    RwThirdComponent,
    RwFourComponent,
  },
})
export default class RemoteWorkPageComponent extends Vue {}
