
import { Vue, Component } from "vue-property-decorator";
import RwGraphicComponent from "../../_home/components/remote_work/rw_graphic.component.vue";
import nubiUrls from "../../../../../public/nubi_urls.json";
@Component({
  name: "rw-four-component",
  components: { RwGraphicComponent },
})
export default class RwFourComponent extends Vue {
  nubiUrls = nubiUrls;
}
