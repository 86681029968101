
import { Component, Vue } from "vue-property-decorator";
import FooterComponent from "@/components/elements/footer/footer.component.vue";
import HeaderComponent from "@/components/elements/header/header.component.vue";
import HeroComponent from "./components/hero/hero.component.vue";
import RemoteWorkComponent from "./components/remote_work/remote_work.component.vue";
import EducationComponent from "./components/education/education.component.vue";
import RemoteEventsComponent from "./components/remote_events/remote_events.component.vue";
import PricingComponent from "./components/princing/princing.component.vue";
import SuccessCasesComponent from "./components/success_cases/success_cases.component.vue";
import IntroComponent from "./components/intro/intro.component.vue";
import nubiUrls from "../../../../public/nubi_urls";
import AvatarComponent from "@/components/elements/avatar/avatar.component.vue";
import { TweenMax, TimelineMax } from "gsap";

import AOS from "aos";
import CookieMessageComponent from "./cookieMessage.component.vue";
@Component({
  name: "home-component",
  components: {
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    RemoteWorkComponent,
    EducationComponent,
    RemoteEventsComponent,
    PricingComponent,
    SuccessCasesComponent,
    IntroComponent,
    AvatarComponent,
    CookieMessageComponent,
  },
})
export default class HomeComponent extends Vue {
  nubiUrls = nubiUrls;
  points1 = [
    { left: "1.8rem", top: "27rem" },
    { left: "6rem", top: "31rem" },
    { left: "24rem", top: "31rem" },
    { left: "28rem", top: "35rem" },
  ];
  avatar = localStorage.getItem("avatar") || 6;
  mounted() {
    AOS.init();
    window.addEventListener("avatar-localstorage-changed", (event) => {
      this.avatar = event.detail.storage;
    });
    const avatar = this.avatar;
    const scene1 = this.$scrollmagic
      .scene({
        triggerElement: "#start",
        triggerHook: 0.5,
        duration: 5,
      })
      .setTween(`#avatar-${avatar}`, {
        css: {
          opacity: 0,
        },
      });
    //.addIndicators({ name: "1 (duration: 300)" });

    var tween = new TimelineMax()
      .add(TweenMax.to("#avatar-move", 0.1, { css: { opacity: 1 } }))
      .add(
        TweenMax.to("#avatar-move", 0.5, { css: { transform: "scale(1.2)" } })
      )
      .add(
        TweenMax.to("#avatar-move", 1, { css: { transform: "scale(0.6)" } })
      );
    const scene2 = this.$scrollmagic
      .scene({
        triggerElement: "#start",
        triggerHook: 0.5,
        duration: 300,
      })
      // Declaration of animation and attaching to element

      .setTween(tween);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "2 (duration: 300)" });
    /*  const tweenPipe2 = new TimelineMax()
      .add(TweenMax.to("#avatar-move-rw", 0.5, { css: { display: "none" } }))
      .add(
        TweenMax.to("#avatar-move-pipe1", 2, {
          css: {
            left: `calc(${
              document.getElementById("pipe1-point-0").style.left
            } )`,
            top: `calc(${document.getElementById("pipe1-point-0").style.top} )`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-pipe1", 1, {
          css: {
            transform: "rotate(270deg)",
            left: `calc(${
              document.getElementById("pipe1-point-1").style.left
            } )`,
            top: `calc(${document.getElementById("pipe1-point-1").style.top})`,
          },
        })
      ); */
    /* const pipe1Scene = this.$scrollmagic
      .scene({
        triggerElement: "#pipe2",
        triggerHook: 0.5,
        duration: 500,
      })
      // Declaration of animation and attaching to element

      .setTween(tweenPipe2)
      // Helpful tags for orientation on the screen
      .addIndicators({ name: "pipe2 (duration: 300)" });
 */
    this.$scrollmagic.addScene(scene1);
    this.$scrollmagic.addScene(scene2);
    // this.$scrollmagic.addScene(pipe1Scene);
  }
  getNubiUrl() {
    return this.$route.name === "school" ? "school_form" : "visit-nubi";
  }
}
