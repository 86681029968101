
import AvatarComponent from "@/components/elements/avatar/avatar.component.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { TweenMax, TimelineMax } from "gsap";
@Component({
  name: "e-graphic-component",
  components: { AvatarComponent },
})
export default class EGraphicComponent extends Vue {
  @Prop({ default: "" }) type: string;
  avatar = localStorage.getItem("avatar");
  points = [
    { left: "24.5rem", top: "8rem" },
    { left: "21rem", top: "13rem" },
    { left: "14rem", top: "18rem" },
    { left: "11rem", top: "13rem" },
    { left: "8rem", top: "12rem" },
    { left: "3.6rem", top: "12rem" },
  ];
  mounted() {
    window.addEventListener("avatar-localstorage-changed", (event) => {
      this.avatar = event.detail.storage;
    });
    var tween = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-e", 0.5, {
          css: {
            display: "block",
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-e", 1, {
          css: {
            left: `calc(${
              document.getElementById("e-point-0").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("e-point-0").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-e", 2, {
          css: {
            left: `calc(${
              document.getElementById("e-point-1").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("e-point-1").style.top
            } - 40px)`,
          },
        })
      );
    const scene1 = this.$scrollmagic
      .scene({
        triggerElement: "#e-point-0",
        triggerHook: 0.5,
        duration: 50,
      })
      // Declaration of animation and attaching to element

      .setTween(tween);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "2 (duration: 300)" });
    var tween2 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-e", 1, {
          css: {
            left: `calc(${
              document.getElementById("e-point-2").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("e-point-2").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-e", 2, {
          css: {
            left: `calc(${
              document.getElementById("e-point-3").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("e-point-3").style.top
            } - 40px)`,
          },
        })
      );

    const scene2 = this.$scrollmagic
      .scene({
        triggerElement: "#e-point-3",
        triggerHook: 0.5,
        duration: 100,
      })
      // Declaration of animation and attaching to element

      .setTween(tween2);
    // Helpful tags for orientation on the screen
    //.addIndicators({ name: "2 (duration: 300)" });

    var tween3 = new TimelineMax()
      .add(
        TweenMax.to("#avatar-move-e", 1, {
          css: {
            left: `calc(${
              document.getElementById("e-point-4").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("e-point-4").style.top
            } - 40px)`,
          },
        })
      )
      .add(
        TweenMax.to("#avatar-move-e", 2, {
          css: {
            left: `calc(${
              document.getElementById("e-point-5").style.left
            } - 1rem)`,
            top: `calc(${
              document.getElementById("e-point-5").style.top
            } - 40px)`,
          },
        })
      );
    const scene3 = this.$scrollmagic
      .scene({
        triggerElement: "#e-point-2",
        triggerHook: 0.5,
        duration: 50,
      })
      // Declaration of animation and attaching to element

      .setTween(tween3);
    // Helpful tags for orientation on the screen
    // .addIndicators({ name: "3 (duration: 300)" });

    this.$scrollmagic.addScene(scene1);
    this.$scrollmagic.addScene(scene2);
    this.$scrollmagic.addScene(scene3);
  }
}
