
import { Component, Vue } from "vue-property-decorator";
import EGraphicComponent from "./e_graphic.component.vue";
@Component({
  name: "education-component",
  components: { EGraphicComponent },
})
export default class EducationComponent extends Vue {
  goTo(link) {
    this.$router.push({ name: link });
  }
  nextLevel() {
    const id = "events";
    const yOffset = 125;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }
}
