import axios from "axios";

export default class MainService {
  async getSpacesTypes(spaceType: string): Promise<any> {
    return await axios.get(
      `https://api.nubi.city/api/scenario_types?spaceType=${spaceType}&landing=true`
    );
  }

  async postSpaceRequest(params): Promise<any> {
    return await axios.post("https://api.nubi.city/api/spaces/request", params);
  }
}
